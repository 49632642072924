export const PODZIAL = {
  "1": {
    "powiaty": [
      "bolesławiecki",
      "głogowski",
      "jaworski",
      "kamiennogórski",
      "karkonoski",
      "legnicki",
      "lubański",
      "lubiński",
      "lwówecki",
      "polkowicki",
      "zgorzelecki",
      "złotoryjski"
    ],
    "miasta": ["Jelenia Góra", "Legnica"]
  },
  "2": {
    "powiaty": [
      "dzierżoniowski",
      "kłodzki",
      "świdnicki",
      "wałbrzyski",
      "ząbkowicki"
    ],
    "miasta": ["Wałbrzych"]
  },
  "3": {
    "powiaty": [
      "górowski",
      "milicki",
      "oleśnicki",
      "oławski",
      "strzeliński",
      "średzki",
      "trzebnicki",
      "wołowski",
      "wrocławski"
    ],
    "miasta": ["Wrocław"]
  },
  "4": {
    "powiaty": [
      "bydgoski",
      "inowrocławski",
      "mogileński",
      "nakielski",
      "sępoleński",
      "świecki",
      "tucholski",
      "żniński"
    ],
    "miasta": ["Bydgoszcz"]
  },
  "5": {
    "powiaty": [
      "aleksandrowski",
      "brodnicki",
      "chełmiński",
      "golubsko-dobrzyński",
      "grudziądzki",
      "lipnowski",
      "radziejowski",
      "rypiński",
      "toruński",
      "wąbrzeski",
      "włocławski"
    ],
    "miasta": ["Grudziądz", "Toruń", "Włocławek"]
  },
  "6": {
    "powiaty": [
      "janowski",
      "kraśnicki",
      "lubartowski",
      "lubelski",
      "łęczyński",
      "łukowski",
      "opolski",
      "puławski",
      "rycki",
      "świdnicki"
    ],
    "miasta": ["Lublin"]
  },
  "7": {
    "powiaty": [
      "bialski",
      "biłgorajski",
      "chełmski",
      "hrubieszowski",
      "krasnostawski",
      "parczewski",
      "radzyński",
      "tomaszowski",
      "włodawski",
      "zamojski"
    ],
    "miasta": ["Biała Podlaska", "Chełm", "Zamość"]
  },
  "8": {
    "województwo": "lubuskie"
  },
  "9": {
    "powiaty": ["brzeziński", "łódzki wschodni"],
    "miasta": ["Łódź"]
  },
  "10": {
    "powiaty": [
      "bełchatowski",
      "opoczyński",
      "piotrkowski",
      "radomszczański",
      "rawski",
      "skierniewicki",
      "tomaszowski"
    ],
    "miasta": ["Piotrków Trybunalski", "Skierniewice"]
  },
  "11": {
    "powiaty": [
      "kutnowski",
      "łaski",
      "łęczycki",
      "łowicki",
      "pabianicki",
      "pajęczański",
      "poddębicki",
      "sieradzki",
      "wieluński",
      "wieruszowski",
      "zduńskowolski",
      "zgierski"
    ]
  },
  "12": {
    "powiaty": [
      "chrzanowski",
      "myślenicki",
      "oświęcimski",
      "suski",
      "wadowicki"
    ]
  },
  "13": {
    "powiaty": ["krakowski", "miechowski", "olkuski"],
    "miasta": ["Kraków"]
  },
  "14": {
    "powiaty": [
      "gorlicki",
      "limanowski",
      "nowosądecki",
      "nowotarski",
      "tatrzański"
    ],
    "miasta": ["Nowy Sącz"]
  },
  "15": {
    "powiaty": [
      "bocheński",
      "brzeski",
      "dąbrowski",
      "proszowicki",
      "tarnowski",
      "wielicki"
    ],
    "miasta": ["Tarnów"]
  },
  "16": {
    "powiaty": [
      "ciechanowski",
      "gostyniński",
      "mławski",
      "płocki",
      "płoński",
      "przasnyski",
      "sierpecki",
      "sochaczewski",
      "żuromiński",
      "żyrardowski"
    ],
    "miasta": ["Płock"]
  },
  "17": {
    "powiaty": [
      "białobrzeski",
      "grójecki",
      "kozienicki",
      "lipski",
      "przysuski",
      "radomski",
      "szydłowiecki",
      "zwoleński"
    ],
    "miasta": ["Radom"]
  },
  "18": {
    "powiaty": [
      "garwoliński",
      "łosicki",
      "makowski",
      "miński",
      "ostrołęcki",
      "ostrowski",
      "pułtuski",
      "siedlecki",
      "sokołowski",
      "węgrowski",
      "wyszkowski"
    ],
    "miasta": ["Ostrołęka", "Siedlce"]
  },
  "19": {
    "miasta": ["Warszawa"]
  },
  "20": {
    "powiaty": [
      "grodziski",
      "legionowski",
      "nowodworski",
      "otwocki",
      "piaseczyński",
      "pruszkowski",
      "warszawski zachodni",
      "wołomiński"
    ]
  },
  "21": {
    "województwo": "opolskie"
  },
  "22": {
    "powiaty": [
      "bieszczadzki",
      "brzozowski",
      "jarosławski",
      "jasielski",
      "krośnieński",
      "leski",
      "lubaczowski",
      "przemyski",
      "przeworski",
      "sanocki",
      "Krosno",
      "Przemyśl"
    ]
  },
  "23": {
    "powiaty": [
      "dębicki",
      "kolbuszowski",
      "leżajski",
      "łańcucki",
      "mielecki",
      "niżański",
      "ropczycko-sędziszowski",
      "rzeszowski",
      "stalowowolski",
      "strzyżowski",
      "tarnobrzeski",
      "Rzeszów",
      "Tarnobrzeg"
    ]
  },
  "24": {
    "województwo": "podlaskie"
  },
  "25": {
    "powiaty": [
      "gdański",
      "kwidzyński",
      "malborski",
      "nowodworski",
      "starogardzki",
      "sztumski",
      "tczewski"
    ],
    "miasta": ["Gdańsk", "Sopot"]
  },
  "26": {
    "powiaty": [
      "bytowski",
      "chojnicki",
      "człuchowski",
      "kartuski",
      "kościerski",
      "lęborski",
      "pucki",
      "słupski",
      "wejherowski"
    ],
    "miasta": ["Gdynia", "Słupsk"]
  },
  "27": {
    "powiaty": ["bielski", "cieszyński", "pszczyński", "żywiecki"],
    "miasta": ["Bielsko-Biała"]
  },
  "28": {
    "powiaty": ["częstochowski", "kłobucki", "lubliniecki", "myszkowski"],
    "miasta": ["Częstochowa"]
  },
  "29": {
    "powiaty": ["gliwicki", "tarnogórski"],
    "miasta": ["Bytom", "Gliwice", "Zabrze"]
  },
  "30": {
    "powiaty": ["mikołowski", "raciborski", "rybnicki", "wodzisławski"],
    "miasta": ["Jastrzębie-Zdrój", "Rybnik", "Żory"]
  },
  "31": {
    "powiaty": ["bieruńsko-lędziński"],
    "miasta": [
      "Chorzów",
      "Katowice",
      "Mysłowice",
      "Piekary Śląskie",
      "Ruda Śląska",
      "Siemianowice Śląskie",
      "Świętochłowice",
      "Tychy"
    ]
  },
  "32": {
    "powiaty": ["będziński", "zawierciański"],
    "miasta": ["Dąbrowa Górnicza", "Jaworzno", "Sosnowiec"]
  },
  "33": {
    "województwo": "świętokrzyskie"
  },
  "34": {
    "powiaty": [
      "bartoszycki",
      "braniewski",
      "działdowski",
      "elbląski",
      "iławski",
      "lidzbarski",
      "nowomiejski",
      "ostródzki"
    ],
    "miasta": ["Elbląg"]
  },
  "35": {
    "powiaty": [
      "ełcki",
      "giżycki",
      "gołdapski",
      "kętrzyński",
      "mrągowski",
      "nidzicki",
      "olecki",
      "olsztyński",
      "piski",
      "szczycieński",
      "węgorzewski"
    ],
    "miasta": ["Olsztyn"]
  },
  "36": {
    "powiaty": [
      "gostyński",
      "jarociński",
      "kaliski",
      "kępiński",
      "kościański",
      "krotoszyński",
      "leszczyński",
      "ostrowski",
      "ostrzeszowski",
      "pleszewski",
      "rawicki"
    ],
    "miasta": ["Kalisz", "Leszno"]
  },
  "37": {
    "powiaty": [
      "gnieźnieński",
      "kolski",
      "koniński",
      "słupecki",
      "średzki",
      "śremski",
      "turecki",
      "wrzesiński"
    ],
    "miasta": ["Konin"]
  },
  "38": {
    "powiaty": [
      "chodzieski",
      "czarnkowsko-trzcianecki",
      "grodziski",
      "międzychodzki",
      "nowotomyski",
      "obornicki",
      "pilski",
      "szamotulski",
      "wągrowiecki",
      "wolsztyński",
      "złotowski"
    ]
  },
  "39": {
    "powiaty": ["poznański", "Poznań"]
  },
  "40": {
    "powiaty": [
      "białogardzki",
      "choszczeński",
      "drawski",
      "kołobrzeski",
      "koszaliński",
      "sławieński",
      "szczecinecki",
      "świdwiński",
      "wałecki"
    ],
    "miasta": ["Koszalin"]
  },
  "41": {
    "powiaty": [
      "goleniowski",
      "gryficki",
      "gryfiński",
      "kamieński",
      "łobeski",
      "myśliborski",
      "policki",
      "pyrzycki",
      "stargardzki"
    ],
    "miasta": ["Szczecin", "Świnoujście"]
  }
}
