import { Sondaze } from "./typy";

export const WYNIKI_2023 = [
  {
    TD: 53958,
    LEWICA: 47715,
    PIS: 174643,
    KONF: 31770,
    KO: 169540,
  },
  {
    TD: 39215,
    LEWICA: 25806,
    PIS: 107797,
    KONF: 19478,
    KO: 120188,
  },
  {
    TD: 106624,
    LEWICA: 88089,
    PIS: 206899,
    KONF: 54132,
    KO: 286713,
  },
  {
    TD: 80426,
    LEWICA: 52959,
    PIS: 162603,
    KONF: 34266,
    KO: 186914,
  },
  {
    TD: 84308,
    LEWICA: 60473,
    PIS: 183131,
    KONF: 34232,
    KO: 158719,
  },
  {
    TD: 102894,
    LEWICA: 37083,
    PIS: 294847,
    KONF: 54325,
    KO: 131712,
  },
  {
    TD: 59577,
    LEWICA: 25691,
    PIS: 231882,
    KONF: 35594,
    KO: 79501,
  },
  {
    TD: 77933,
    LEWICA: 47911,
    PIS: 143530,
    KONF: 33672,
    KO: 195091,
  },
  {
    TD: 54283,
    LEWICA: 55770,
    PIS: 122433,
    KONF: 25428,
    KO: 187527,
  },
  {
    TD: 54479,
    LEWICA: 25340,
    PIS: 184929,
    KONF: 30247,
    KO: 86083,
  },
  {
    TD: 77313,
    LEWICA: 41188,
    PIS: 221031,
    KONF: 36383,
    KO: 138038,
  },
  {
    TD: 54585,
    LEWICA: 22036,
    PIS: 156308,
    KONF: 28754,
    KO: 88408,
  },
  {
    TD: 127693,
    LEWICA: 83633,
    PIS: 232430,
    KONF: 58435,
    KO: 232799,
  },
  {
    TD: 49487,
    LEWICA: 13594,
    PIS: 229587,
    KONF: 37301,
    KO: 68804,
  },
  {
    TD: 75229,
    LEWICA: 16152,
    PIS: 196433,
    KONF: 32241,
    KO: 68690,
  },
  {
    TD: 75526,
    LEWICA: 28848,
    PIS: 195218,
    KONF: 28877,
    KO: 99146,
  },
  {
    TD: 54690,
    LEWICA: 20874,
    PIS: 190418,
    KONF: 28593,
    KO: 82003,
  },
  {
    TD: 83681,
    LEWICA: 26149,
    PIS: 262236,
    KONF: 44299,
    KO: 100902,
  },
  {
    TD: 227127,
    LEWICA: 230648,
    PIS: 345380,
    KONF: 124220,
    KO: 741286,
  },
  {
    TD: 110086,
    LEWICA: 51556,
    PIS: 231905,
    KONF: 51573,
    KO: 257470,
  },
  {
    TD: 61155,
    LEWICA: 34763,
    PIS: 150022,
    KONF: 31150,
    KO: 161241,
  },
  {
    TD: 60938,
    LEWICA: 19750,
    PIS: 241790,
    KONF: 38080,
    KO: 70054,
  },
  {
    TD: 83676,
    LEWICA: 32828,
    PIS: 347688,
    KONF: 63854,
    KO: 119259,
  },
  {
    TD: 114898,
    LEWICA: 29478,
    PIS: 258277,
    KONF: 59648,
    KO: 126971,
  },
  {
    TD: 90599,
    LEWICA: 57967,
    PIS: 155318,
    KONF: 38406,
    KO: 257009,
  },
  {
    TD: 92793,
    LEWICA: 56887,
    PIS: 199709,
    KONF: 49203,
    KO: 258909,
  },
  {
    TD: 64778,
    LEWICA: 34601,
    PIS: 163506,
    KONF: 34909,
    KO: 127677,
  },
  {
    TD: 47698,
    LEWICA: 30497,
    PIS: 117756,
    KONF: 21256,
    KO: 94313,
  },
  {
    TD: 51681,
    LEWICA: 35673,
    PIS: 116827,
    KONF: 26934,
    KO: 139711,
  },
  {
    TD: 47525,
    LEWICA: 26117,
    PIS: 145230,
    KONF: 30527,
    KO: 114404,
  },
  {
    TD: 69825,
    LEWICA: 44509,
    PIS: 162458,
    KONF: 35240,
    KO: 193596,
  },
  {
    TD: 37221,
    LEWICA: 81646,
    PIS: 112389,
    KONF: 21512,
    KO: 114519,
  },
  {
    TD: 90975,
    LEWICA: 45048,
    PIS: 310266,
    KONF: 43197,
    KO: 137941,
  },
  {
    TD: 46101,
    LEWICA: 24269,
    PIS: 105373,
    KONF: 19590,
    KO: 95410,
  },
  {
    TD: 63007,
    LEWICA: 31631,
    PIS: 126432,
    KONF: 27119,
    KO: 129339,
  },
  {
    TD: 87628,
    LEWICA: 46222,
    PIS: 194416,
    KONF: 37838,
    KO: 154990,
  },
  {
    TD: 69740,
    LEWICA: 39761,
    PIS: 162192,
    KONF: 29208,
    KO: 100580,
  },
  {
    TD: 72996,
    LEWICA: 32378,
    PIS: 120301,
    KONF: 28370,
    KO: 144114,
  },
  {
    TD: 98589,
    LEWICA: 73345,
    PIS: 116666,
    KONF: 35182,
    KO: 262779,
  },
  {
    TD: 39776,
    LEWICA: 28101,
    PIS: 101023,
    KONF: 19379,
    KO: 124625,
  },
  {
    TD: 69957,
    LEWICA: 52032,
    PIS: 159575,
    KONF: 32942,
    KO: 222427,
  },
];
