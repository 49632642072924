import { KOLOR_PARTII } from "./dane";
import { ILE_SYMULACJI, Okreg, PoparciePartii, Sondaze, Wynik } from "./typy";
import Chart, { ChartItem } from "chart.js/auto";
import Color from 'color';

export function rysujWynikWyborow(wynikWyborow: Sondaze, targetElement: string, options: { poziomo?: boolean, laczOpozycje?: boolean, calySejm?: boolean } = {}) {
  const canvas = document.createElement("canvas");
  document.getElementById(targetElement).appendChild(canvas);
    new Chart(canvas as ChartItem, {
      type: "bar",
      data: {
        labels: Object.entries(wynikWyborow).map(([sondaz, wynik]) =>
          sondaz.toString()
        ),
        datasets: !options.laczOpozycje
          ? [
              {
                label: "Lewica",
                data: Object.values(wynikWyborow).map((wynik) => wynik.LEWICA),
                backgroundColor: KOLOR_PARTII["LEWICA"],
              },
              {
                label: "KO",
                data: Object.values(wynikWyborow).map((wynik) => wynik.KO),
                backgroundColor: KOLOR_PARTII["KO"],
              },
              {
                label: "Trzecia Droga",
                data: Object.values(wynikWyborow).map((wynik) => wynik.TD),
                backgroundColor: KOLOR_PARTII["TD"],
              },
              {
                label: "PiS + Konfederacja",
                data: Object.values(wynikWyborow).map((wynik) => wynik.NIEDEMO),
                backgroundColor: "rgb(128, 0, 32)",
              },
            ]
          : [
              {
                label: "Opozycja Demokratyczna",
                data: Object.values(wynikWyborow).map((wynik) => wynik.DEMO),
                backgroundColor: "#E56701",
              },
              {
                label: "PiS + Konfederacja",
                data: Object.values(wynikWyborow).map((wynik) => wynik.NIEDEMO),
                backgroundColor: "rgb(128, 0, 32)",
              },
            ],
      },
      options: options?.poziomo
        ? {
            indexAxis: "y",
            scales: {
              x: {
                stacked: true,
                max: options?.calySejm ? 460 : null,
                afterBuildTicks: options?.calySejm
                  ? (axis) =>
                      (axis.ticks = [0, 230, 460].map((v) => ({ value: v })))
                  : null,
              },
              y: {
                stacked: true,
              },
            },
          }
        : {},
    });
}

export function odmianaSlowaMandat(mandaty: number) {
  return [0, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].includes(mandaty)
              ? "mandatów"
              : mandaty > 1
              ? "mandaty"
              : "mandat"
}

export function rysujHistogram(
  histogram: PoparciePartii,
  targetElement: string,
  partia: string,
  procentZSondazy: string,
  procentZWyborow: string,
  odchylenie: number,
  minimumMandatow: Wynik,
  okreg: Okreg
) {
  const canvas = document.createElement("canvas");
  document.getElementById(targetElement).appendChild(canvas);
  const color = KOLOR_PARTII[partia];
  const procenty = Object.keys(histogram).sort(
    (a, b) => Number.parseFloat(b) - Number.parseFloat(a)
  );

  const footer = (tooltipItems) => {
    let sum = 0;

    tooltipItems.forEach(function (tooltipItem) {
      sum += Number(tooltipItem.label.split("%")[0]) * okreg.glosy2019;
    });
    return "Głosów: " + Math.round(sum / 100);
  };

  new Chart(canvas as ChartItem, {
    type: "bar",
    data: {
      labels: procenty.map((procent) => `${procent}%`),
      datasets: (partia === "KO" || [3, 19].includes(okreg.nr)
        ? [
            {
              label: `${minimumMandatow[partia] + 4} ${odmianaSlowaMandat(
                minimumMandatow[partia] + 4
              )}`,
              data: procenty.map(
                (procent) =>
                  ((histogram[procent][String(minimumMandatow[partia] + 4)] ||
                    0) /
                    ILE_SYMULACJI) *
                  100
              ),
              backgroundColor: Color(color).darken(0.4).toString(),
            },
            {
              label: `${minimumMandatow[partia] + 3} ${odmianaSlowaMandat(
                minimumMandatow[partia] + 3
              )}`,
              data: procenty.map(
                (procent) =>
                  ((histogram[procent][String(minimumMandatow[partia] + 3)] ||
                    0) /
                    ILE_SYMULACJI) *
                  100
              ),
              backgroundColor: Color(color).darken(0.2).toString(),
            },
          ]
        : []
      ).concat([
        {
          label: `${minimumMandatow[partia] + 2} ${odmianaSlowaMandat(
            minimumMandatow[partia] + 2
          )}`,
          data: procenty.map(
            (procent) =>
              ((histogram[procent][String(minimumMandatow[partia] + 2)] || 0) /
                ILE_SYMULACJI) *
              100
          ),
          backgroundColor: color,
        },
        {
          label: `${minimumMandatow[partia] + 1} ${odmianaSlowaMandat(
            minimumMandatow[partia] + 1
          )}`,
          data: procenty.map(
            (procent) =>
              ((histogram[procent][String(minimumMandatow[partia] + 1)] || 0) /
                ILE_SYMULACJI) *
              100
          ),
          backgroundColor: Color(color).fade(0.4).toString(),
        },
        {
          label: `${minimumMandatow[partia]} ${odmianaSlowaMandat(
            minimumMandatow[partia]
          )}`,
          data: procenty.map(
            (procent) =>
              ((histogram[procent][String(minimumMandatow[partia])] || 0) /
                ILE_SYMULACJI) *
              100
          ),
          backgroundColor: Color(color).fade(0.8).toString(),
        },
      ]),
    },
    options: {
      plugins: {
        legend: {
          title: {
            display: true,
            text: `Faktyczny/przewidywany wynik dla ${partia}: ${procentZWyborow} / ${procentZSondazy}%, σ: ${
              Math.round(odchylenie * 10) / 10
            }`,
            color: "black",
            font: {
              size: 15,
            },
          },
        },
        tooltip: {
          callbacks: {
            footer: footer,
          },
        },
      },
      scales: {
        x: {
          stacked: true,
        },
        y: {
          display: false,
          stacked: true,
        },
      },
    },
  });
  const tekst = document.createElement("p");
  // tekst.setAttribute("style", "");
  // tekst.innerHTML = interpretacjaHistogramu(partia, String(minimumMandatow[partia]), histogram);
  document.getElementById(targetElement).appendChild(tekst);
}

// function extractProcent(poparcie: PoparciePartii) {
//   return Number(poparcie.procent);
// }

// function interpretacjaHistogramu(partia: string, oczekiwaneMandaty: string, histogram: PoparciePartii) {
//   const procentyOczekiwanych = Object.entries(histogram).filter(
//     ([ mandaty, czestosc ]) => mandaty === oczekiwaneMandaty
//   );
//   const minProcentDoOczekiwanych = procentyOczekiwanych.reduce((acc, curr) =>
//     extractProcent(curr) < extractProcent(acc) ? curr : acc
//   );
//   const maxProcentDoOczekiwanych = procentyOczekiwanych.reduce((acc, curr) =>
//     extractProcent(curr) > extractProcent(acc) ? curr : acc
//   );

//   let tekst = `Jeśli ${partia} otrzyma`;
//   // Nie podajemy dolnego zakresu, gdy oczekujemy 0 mandatów
//   tekst += oczekiwaneMandaty
//     ? ` od ${Math.max(extractProcent(minProcentDoOczekiwanych), 0)}%`
//     : "";
//   tekst += ` do ${
//     maxProcentDoOczekiwanych.procent
//   }% głosów, to zdobędzie ${oczekiwaneMandaty} ${odmianaSlowaMandat(
//     oczekiwaneMandaty
//   )}.`;
//   return tekst;
// }