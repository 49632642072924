import { Wynik, Okreg, Sondaze,  Histogram, Rekomendacje, ILE_SYMULACJI } from "./typy";
import { okregi, KOLOR_PARTII, sredniaSondazy } from "./dane";
import {
  NaPolDemo,
  PodzialMandatowWOkregu,
  RozkladPrawdopodobienstwaWOkregu,
  odchylenie,
} from "./ordynacja";
import {
  odmianaSlowaMandat,
  rysujHistogram,
  rysujWynikWyborow,
} from "./wykresy";
import { komentarze } from "./komentarz";
import { DANE } from "./symulacja";
import { PODZIAL } from "./podzial_terytorialny";
import { WYNIKI_2023 } from "./wyniki_2023";

let dane = DANE;
// Odkomentuj aby przegenerować dane
// okregi.forEach((okreg) => {
//   const daneZOkregu = RozkladPrawdopodobienstwaWOkregu(
//     sredniaSondazy as Wynik,
//     okreg
//   );
//   dane[okreg.miasto] = daneZOkregu;
// });
// console.log(dane);

const wszystkieRekomendacje: Rekomendacje = {};

export function RysujRozkladyWOkregu(wynik: Wynik, okreg: Okreg, lokalizacje: { powiaty?: string[], miasta?: string[], województwo?: string}) {
  const {
    procentyWOkreguSrednia,
    odchylenieWOkregu,
    wykresRozkladu,
    wykresRozkladuDemo,
    histogram,
    minimumMandatow,
    zagrozoneMandaty,
    potencjalneMandaty,
    ostatnieMandaty,
    dawcyGlosow,
  } = dane[okreg.miasto];
  const { powiaty, miasta, województwo } = lokalizacje;
  const header = document.createElement("h2");
  header.innerHTML = `${okreg.miasto} (${okreg.nr})`;
  header.setAttribute("id", okreg.miasto);
  header.setAttribute("class", "miastoHeader");
  document.getElementById("analizy").appendChild(header);
  const skladnikiOkregu = document.createElement("p");
  skladnikiOkregu.setAttribute("class", 'skladniki_okregu')
  skladnikiOkregu.innerHTML = województwo ? `województwo: <b>${województwo}</b>` : '';
  skladnikiOkregu.innerHTML += powiaty ? `<b>powiaty</b>: ${powiaty.join(', ')}` : '';
  skladnikiOkregu.innerHTML += powiaty && miasta ? "<br/>" : "";
  skladnikiOkregu.innerHTML += miasta ? `<b>miasta</b>: ${miasta.join(", ")}` : "";
  document.getElementById("analizy").appendChild(skladnikiOkregu);

  wstawRekomendowaneLogos(okreg, document.getElementById("analizy"));

  wszystkieRekomendacje[okreg.miasto] = {
    nazwaOkregu: okreg.miasto,
    nrOkregu: okreg.nr,
    rekomendacjePartii: komentarze[okreg.miasto].rekomendacjePartii,
  };

  const kontener = document.createElement("div");
  const wykres = document.createElement("div");
  const wykresDemo = document.createElement("div");
  const naglowek = document.createElement("h3");

  kontener.setAttribute("class", "kontener");

  naglowek.innerHTML = "Prawdopodobieństwo podziału mandatów";
  document.getElementById("analizy").appendChild(naglowek);

  wykres.setAttribute("class", "wykres");
  wykres.setAttribute("id", "Miasto" + okreg.miasto);
  wykresDemo.setAttribute("class", "wykres");
  wykresDemo.setAttribute("id", "Miasto" + okreg.miasto + "Demo");

  kontener.appendChild(wykres);
  kontener.appendChild(wykresDemo);
  document.getElementById("analizy").appendChild(kontener);
  const tekst = document.createElement("p");
  tekst.setAttribute("style", "margin-bottom: 30px; ");
  tekst.innerHTML = interpretacjaWykresuRozkladuDemo(
    wykresRozkladuDemo,
    okreg
  );
  document.getElementById("analizy").appendChild(tekst);
  const tekstHistogramy = document.createElement("p");
  tekstHistogramy.setAttribute("style", "margin-bottom: 15px; ");
  tekstHistogramy.innerHTML = 'Wykresy poniżej pokazują prawdopodobieństwo poparcia danej partii. Im wyższy słupek, tym większa szansa, że partia dostanie określony procent głosów.'
  document.getElementById("analizy").appendChild(tekstHistogramy);
  rysujHistogramy(
    okreg,
    histogram,
    procentyWOkreguSrednia,
    odchylenieWOkregu,
    minimumMandatow
  );

  function getFirstXEntries(
    obj: Sondaze,
    x: number
  ): Sondaze {
    return Object.keys(obj)
      .slice(0, x)
      .reduce((acc, key) => {
        acc[key] = obj[key];
        return acc;
      }, {} as Sondaze);
  }

  rysujWynikWyborow(wykresRozkladuDemo, "Miasto" + okreg.miasto, {
    poziomo: true,
    laczOpozycje: true,
  });
  const mandaty = NaPolDemo(PodzialMandatowWOkregu(
    WYNIKI_2023[okreg.nr - 1],
    okreg,
    { log: false, inputWGlosach: true }
  ));
  rysujWynikWyborow(
    { PKW: mandaty, ...wykresRozkladu }, 
    "Miasto" + okreg.miasto + "Demo", 
      {
      poziomo: true,
      laczOpozycje: false,
    });
  // Rysuj kopie przy mapie
  rysujWynikWyborow(wykresRozkladuDemo,
    "WykresMiasto" + okreg.miasto,
    {
      poziomo: true,
      laczOpozycje: true,
    }
  );
  rysujWynikWyborow(getFirstXEntries(wykresRozkladu, 6), "WykresMiasto" + okreg.miasto + "Demo", {
    poziomo: true,
    laczOpozycje: false,
  });

  // Opcjonalne...
  // const symulacje = document.createElement("p");
  // symulacje.setAttribute("class", "symulacje");
  // let tabelka = '<table>';
  // tabelka +=
  //   "<th>Partia</th><th>Ostatni mandat</th><th>Zagrożenie</th><th>Dawca głosów</th><th>Szansa</th>";
  // for (const partia of ["KO", "TD", "LEWICA"]) {
  //   tabelka += `<tr><td>${partia}</td><td>${Math.round(
  //     (100 * ostatnieMandaty[partia]) / ILE_SYMULACJI
  //   )}%</td><td>${Math.round(
  //     (100 * zagrozoneMandaty[partia]) / ILE_SYMULACJI
  //   )}%</td><td>${Math.round(
  //     (100 * dawcyGlosow[partia]) / ILE_SYMULACJI
  //   )}%</td><td>${Math.round(
  //     (100 * potencjalneMandaty[partia]) / ILE_SYMULACJI
  //   )}%</td></tr>`;
  // }
  // let legenda = "<b>Ostatni mandat</b> oznacza w ilu procent symulacji partia bierze ostatni przydzielony mandat, co jest najbardziej efektywne wykorzystanie, ale i ryzykowne gdyby głosów było mniej niż wypadło w symulacji.<br/> "
  // legenda +=
  //   `<b>Zagrożenie</b> oznacza w ilu procent symulacji partia zdobywa do 20% ceny mandatu powyżej tego co niezbędne do wzięcia jej ostatniego mandatu. Jest to efektywne, ale wiąże się z ryzykiem, choć nie aż takim jak ostatni mandat.<br/> `;
  // legenda += `<b>Dawca głosów</b> oznacza w ilu procent symulacji partia zdobywa między 25% a 75% ceny mandatu powyżej tego co niezbędne do wzięcia jej ostatniego mandatu. Taki mandat jest bezpieczny, choć nie bardzo efektywny, można więc myśleć o wykorzystaniu tych głosów gdzie indziej.<br/>`;
  // legenda += `<b>Szansa</b> oznacza w ilu procent symulacji partia zdobywa ponad 80% ceny mandatu powyżej tego co niezbędne do wzięcia jej ostatniego mandatu. Jest to sytuacja, w której zdobycie kolejnego mandatu jest "blisko".<br/>`;
  // symulacje.innerHTML = tabelka + "</table>" + legenda;
  // document.getElementById("analizy").appendChild(symulacje);
}

// window.onload = function() {
  let nrOkregu = 1;
  okregi.forEach((okreg) => {
    const text = document.createElement("div");
    text.innerHTML = `<h2>${okreg.miasto} (${nrOkregu})</h2>`;
    text.id = `${okreg.miasto}Info`;
    text.className = "info";

    wstawRekomendowaneLogos(okreg, text);

    const kontener = document.createElement("div");
    kontener.setAttribute("class", "kontener");
    const wykres1 = document.createElement("div");
    wykres1.setAttribute("id", "WykresMiasto" + okreg.miasto);
    wykres1.setAttribute("class", "wykres");
    const wykres2 = document.createElement("div");
    wykres2.setAttribute("id", "WykresMiasto" + okreg.miasto + 'Demo');
    wykres2.setAttribute("class", "wykres");
    kontener.appendChild(wykres1);
    kontener.appendChild(wykres2);
    text.appendChild(kontener);

    const kontenerHistogram = document.createElement("div");
    kontenerHistogram.setAttribute("class", "kontener");
    const graph = document.createElement("div");
    graph.setAttribute("id", "InfoMiasto" + okreg.miasto);
    graph.setAttribute("class", "wykres");
    kontenerHistogram.appendChild(graph);
    const graph2 = document.createElement("div");
    graph2.setAttribute("id", "InfoMiasto2" + okreg.miasto);
    graph2.setAttribute("class", "wykres");
    kontenerHistogram.appendChild(graph2);
    text.appendChild(kontenerHistogram);
    const graph3 = document.createElement("div");
    graph3.setAttribute("id", "InfoMiasto3" + okreg.miasto);
    graph3.setAttribute("class", "wykres");
    text.appendChild(graph3);

    document.getElementById("Info").appendChild(text);
    const icon = document.getElementById(okreg.miasto + 'Icon');
    icon.addEventListener('mouseover', () => {
      text.style.display = 'block';
      document
        .getElementsByClassName("placeholder")[0]
        .setAttribute("style", "display: none");
    });
    icon.addEventListener("mouseout", () => {
      text.style.display = "none";
      if (window.innerWidth > 768) {
      document
        .getElementsByClassName("placeholder")[0]
        .setAttribute("style", "display: block");
      }
    });
    nrOkregu++;
  });
  
  let i = 0;
  for (const okreg of okregi) {
    setTimeout(() => RysujRozkladyWOkregu(sredniaSondazy, okreg, PODZIAL[++i]), 200 + i * 50);
  }

  window.addEventListener(
    "hashchange",
    function () {
      let element = document.getElementById(location.hash.substring(1));
      if (element) element.scrollIntoView();
    },
    false
  );

  // For initial page load:
  if (location.hash) {
      let element = document.getElementById(location.hash.substring(1));
      if (element) element.scrollIntoView();
  }

function wstawRekomendowaneLogos(okreg: Okreg, text: HTMLElement) {
  const rekomendacje = komentarze[okreg.miasto].rekomendacjePartii;
  if (rekomendacje?.length && !(rekomendacje[0] === "")) {
    const rekomendacjeLogos = document.createElement("div");
    const rekomendacjeHeader = document.createElement("h4");
    rekomendacjeHeader.innerHTML = `Nasza rekomendacja:`;
    rekomendacjeLogos.appendChild(rekomendacjeHeader);
    // rekomendacjeLogos.setAttribute("id", "RekomendacjeMiasto" + okreg.miasto);
    for (const partia of rekomendacje) {
      const logoPartii = document.createElement("img");
      logoPartii.setAttribute("style", "padding: 0px 20px;");
      logoPartii.src = `${partia}.png`;
      rekomendacjeLogos.appendChild(logoPartii);
    }
    text.appendChild(rekomendacjeLogos);
    if (rekomendacje.length > 1) {
      const aElement = document.getElementById(okreg.miasto + "Icon");
      const path = aElement.getElementsByTagName("path")[0];
      path.setAttribute(
        "fill",
        `url(#stripes${rekomendacje[0].toLocaleLowerCase()}${rekomendacje[1].toLocaleLowerCase()})`
      );
    } else {
      document.getElementById(okreg.miasto + "Icon").style.fill =
        KOLOR_PARTII[komentarze[okreg.miasto].rekomendacjePartii[0]];
    }
    const komentarz = document.createElement("p");
    komentarz.innerHTML = `${komentarze[okreg.miasto].txt || ''}`;
    text.appendChild(komentarz);
  } else {
    const rekomendacjeHeader = document.createElement("h4");
    rekomendacjeHeader.innerHTML = `Brak Rekomendacji`;
    text.appendChild(rekomendacjeHeader);
    const komentarz = document.createElement("p");
    komentarz.innerHTML = `${komentarze[okreg.miasto].txt || ""}`;
    text.appendChild(komentarz);
    document.getElementById(okreg.miasto + "Icon").style.fill = 'white';
  }
}

function rysujHistogramy(okreg: Okreg, histogram: Histogram, wynikWOkregu: Wynik, odchylenieWOkregu: Wynik, minimumMandatow: Wynik) {
  const histogramKontener = document.createElement("div");
  histogramKontener.setAttribute("class", "kontener");
  document.getElementById("analizy").appendChild(histogramKontener);
  const suma = Object.values(WYNIKI_2023).reduce((acc, cur) => {
    return {
      KO: acc.KO + cur.KO,
      LEWICA: acc.LEWICA + cur.LEWICA,
      KONF: acc.KONF + cur.KONF,
      PIS: acc.PIS + cur.PIS,
      TD: acc.TD + cur.TD,
  }
  }, { TD: 0, LEWICA: 0, PIS: 0, KONF: 0, KO: 0});
  const sumaOkreg = Object.values(WYNIKI_2023[okreg.nr - 1]).reduce((acc, cur) => acc += cur, 0);
  Object.entries(sredniaSondazy).forEach(([partia, procent]) => {
    if (["PIS", "KONF"].includes(partia)) {
      return;
    }
    const elementName = okreg.miasto + "Histogram" + partia;
    const wykresHistogram = document.createElement("div");
    wykresHistogram.setAttribute("id", elementName);
    wykresHistogram.setAttribute("class", "histogram");
    histogramKontener.appendChild(wykresHistogram);
    const glosyWOkregu = WYNIKI_2023[okreg.nr - 1][partia] / sumaOkreg;
    const procentyWOkreguWybory = `${Math.round(1000 * glosyWOkregu) / 10}%`;
    // console.log(sumaOkreg, glosyWOkregu, procentyWOkreguWybory);
    rysujHistogram(
      histogram[partia],
      elementName,
      partia,
      (Math.round(wynikWOkregu[partia] * 5) / 5).toString(),
      procentyWOkreguWybory,
      odchylenieWOkregu[partia],
      minimumMandatow,
      okreg
    );
    // Rysuj kopię obok mapy
    if (
      partia === 'KO'
    ) {
      rysujHistogram(
        histogram[partia],
        "InfoMiasto" + okreg.miasto,
        partia,
        (Math.round(wynikWOkregu[partia] * 5) / 5).toString(),
        procentyWOkreguWybory,
        odchylenieWOkregu[partia],
        minimumMandatow,
        okreg
      );
    }
    if (
      partia === 'TD'
    ) {
      rysujHistogram(
        histogram[partia],
        "InfoMiasto2" + okreg.miasto,
        partia,
        (Math.round(wynikWOkregu[partia] * 5) / 5).toString(),
        procentyWOkreguWybory,
        odchylenieWOkregu[partia],
        minimumMandatow,
        okreg
      );
    }
    if (
      partia === "LEWICA"
    ) {
      rysujHistogram(
        histogram[partia],
        "InfoMiasto3" + okreg.miasto,
        partia,
        (Math.round(wynikWOkregu[partia] * 5) / 5).toString(),
        procentyWOkreguWybory,
        odchylenieWOkregu[partia],
        minimumMandatow,
        okreg
      );
    }
  });
}

function interpretacjaWykresuRozkladuDemo(wykresRozkladuDemo: Sondaze, okreg: Okreg):string {
  const podstawowyScenariusz = Object.entries(wykresRozkladuDemo)[0];
  const drugiScenariusz = Object.entries(wykresRozkladuDemo)[1];

  const szansa = drugiScenariusz[1].DEMO > podstawowyScenariusz[1].DEMO;

  return `W okręgu ${okreg.miasto} z  prawdopodobieństwem ${podstawowyScenariusz[0]} ` +
    `demokratyczna opozycja  zdobędzie ${
      podstawowyScenariusz[1].DEMO
    } ${odmianaSlowaMandat(podstawowyScenariusz[1].DEMO)} ` +
    `a PiS i Konfederacja zdobędą ${
      podstawowyScenariusz[1].NIEDEMO
    } ${odmianaSlowaMandat(podstawowyScenariusz[1].NIEDEMO)}. ` +
    (drugiScenariusz !==
    undefined 
    ? (szansa
        ? `Jest ${drugiScenariusz[0]} szans, że `
        : `Istnieje ${drugiScenariusz[0]} ryzyko, że `) +
        `demokratyczna opozycja zdobędzie ${
          drugiScenariusz[1].DEMO
        } ${odmianaSlowaMandat(drugiScenariusz[1].DEMO)} ` +
        `a PiS i Konfederacja zdobędą ${
          drugiScenariusz[1].NIEDEMO
        } ${odmianaSlowaMandat(drugiScenariusz[1].NIEDEMO)}. <br/>`
    : "<br/>");
}

